export type Factory<T> = () => T;

type ManagementAPIErrorType = [
  {
    message: string;
    code: string;
  }
];

// This is how the errors are returned from the Management API
export type ManagementAPIResourceErrorType = Record<
  string,
  ManagementAPIErrorType
>;

export type ErrorType =
  | string
  | {
      message: string;
      [key: string]: any; // Si nécessaire pour ajouter d'autres propriétés dynamiques
    }
  | ManagementAPIResourceErrorType;

export const isManagementAPIResourceErrorType = (
  value: ErrorType | null
): value is ManagementAPIResourceErrorType => {
  return !(value === null) && !(typeof value === "string");
};

export type APICallState<ResourceType> = {
  value: ResourceType | null;
  error: ErrorType | null;
  loading: boolean;
  abort: () => void;
};

export type NonNullValueCallState<ResourceType> = Omit<
  APICallState<ResourceType>,
  "value"
> & { value: ResourceType };

export type BaseAPIRequestArguments =
  | [RequestInfo]
  | [RequestInfo, RequestInit];

export type BaseAPIRequestFunctionType<
  ResourceType,
  Args extends any[] = any[]
> = (...args: Args) => Promise<ResourceType | null>;

export type SafeAPIRequestFunctionType<ResourceType> = (
  input: RequestInfo,
  init?: RequestInit
) => Promise<ResourceType>;

export type APIRequestFunctionType<Args extends any[] = any[]> = (
  ...args: Args
) => Promise<any>;

export type APIRequestFunctionTypeFromParent<Args extends any[] = any[]> = (
  parentId?: string | number,
  ...args: Args
) => Promise<any>;

export type APIRequestBag<ResourceType = any, Args extends any[] = any[]> = [
  APICallState<ResourceType>,
  APIRequestFunctionType<Args>
];

export type SafeAPIRequestBag<
  ResourceType = any,
  Args extends any[] = any[]
> = [NonNullValueCallState<ResourceType>, APIRequestFunctionType<Args>];

export type SafeAPIRequestBagFromParent<
  ResourceType = any,
  Args extends any[] = any[]
> = [
  NonNullValueCallState<ResourceType>,
  APIRequestFunctionTypeFromParent<Args>
];

export type ResourceListResponse<ResourceType> = ResourceType[];

export type ResourceListCallState<ResourceType> = Omit<
  APICallState<ResourceType>,
  "value"
> & {
  value: ResourceListResponse<ResourceType> | null;
};

export type SafeResourceListCallState<ResourceType> = Omit<
  APICallState<ResourceType>,
  "value"
> & {
  value: ResourceListResponse<ResourceType>;
};

export type ResourceListRequestBag<
  ResourceType,
  Args extends any[] = BaseAPIRequestArguments
> = [
  ResourceListCallState<ResourceType>,
  (...args: Args) => Promise<ResourceListResponse<ResourceType> | null>
];

export type SafeResourceListRequestBag<
  ResourceType,
  Args extends any[] = BaseAPIRequestArguments
> = [
  SafeResourceListCallState<ResourceType>,
  (...args: Args) => Promise<ResourceListResponse<ResourceType>>
];

export type PaginatedResourceListResponse<ResourceType> = {
  count: number;
  next: string | null;
  previous: string | null;
  results: ResourceType[];
};

export type PaginatedListAPICallState<ResourceType> = APICallState<
  PaginatedResourceListResponse<ResourceType>
>;

export type PaginatedResourceListRequestBag<
  ResourceType,
  Args extends any[] = BaseAPIRequestArguments
> = [
  PaginatedListAPICallState<ResourceType>,
  (...args: Args) => Promise<PaginatedResourceListResponse<ResourceType> | null>
];

export type ResourceItemRequestBag<ResourceType> = [
  APICallState<ResourceType>,
  {
    doFetch: () => Promise<ResourceType | null>;
    doPatch: (value: FormData) => Promise<ResourceType | null>;
    doDelete: () => Promise<ResourceType | null>;
  }
];

export type ResourceCreationRequestBag<ResourceType> = [
  APICallState<ResourceType>,
  (value: FormData) => Promise<ResourceType | null>
];

export type ResourceActionRequestBag<ResourceType> = [
  APICallState<ResourceType>,
  (itemId: any, value: any) => Promise<ResourceType | null>
];

export type ResourceListActionRequestBag<ResourceType> = [
  APICallState<ResourceType>,
  (value: any) => Promise<ResourceType | null>
];
