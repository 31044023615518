import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { NotificationContext } from "styleguide/Notification";
import {
  networkErrorNotificationOptions,
  serverErrorNotificationOptions
} from "styleguide/Notification/notificationOptions";
import {
  nullIsEmptyArray,
  QueryParams,
  skipPagination,
  useDeserializer,
  usePrepareQueryParams,
  usePrepareRequest
} from "./helpers";
import {
  APIRequestBag,
  PaginatedResourceListRequestBag,
  PaginatedResourceListResponse,
  ResourceActionRequestBag,
  ResourceCreationRequestBag,
  ResourceItemRequestBag,
  ResourceListActionRequestBag,
  SafeResourceListRequestBag
} from "./types";

export type CallJsonAPISettings = {
  commonInit?: RequestInit;
  overrideBackendErrorManagement?: boolean;
};

const defaultCallJsonAPISettings: CallJsonAPISettings = {};

/**
 * Allows calling a JSON endpoint with loading, error and result management.
 * Errors trigger the error tooltips.
 * CSRF token is automatically added for non-GET requests.
 * JSON body format (Content-Type + stringification) should be taken care of by the calling function.
 *
 * @param settings a settings dictionary for the fetch function
 */
export const useCallJsonAPI = <ResourceType = any>(
  settings?: CallJsonAPISettings
): APIRequestBag<ResourceType> => {
  const { commonInit, overrideBackendErrorManagement } =
    settings || defaultCallJsonAPISettings;
  const [value, setValue] = useState<ResourceType | null>(null);
  const [error, setError] = useState<{ message: string } | string | null>(null);
  const context = useRef(useContext(NotificationContext));
  const abortControllerRef = useRef<AbortController | null>(null);

  // Equivalent to const [loading, setLoading] = useState<boolean>(false)
  // but uses a counter to allow multiple threads to manipulate the loading status
  const [loadingCount, setLoadingCount] = useState<number>(0);
  const loading = loadingCount !== 0;
  const setLoading = (loading: boolean) => {
    setLoadingCount((prevCount) => prevCount + (loading ? 1 : -1));
  };

  const doAbort = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  }, []);

  const doFetch = useCallback(
    async (input: RequestInfo, init?: RequestInit) => {
      if (
        abortControllerRef.current &&
        !abortControllerRef.current.signal.aborted
      ) {
        // A query is already running, do not run it again
        console.warn(
          "Attempted to rerun a request with one already running. If this is expected, you should explicitly abort the previous request."
        );
        return;
      }
      setLoading(true);
      abortControllerRef.current = new AbortController();

      const headers = {
        ...commonInit?.headers,
        ...init?.headers
      };
      const method = init?.method || commonInit?.method || "GET";
      const isWritingRequest = !["GET", "HEAD", "OPTIONS"].includes(method);
      const fullHeaders = {
        ...headers,
        ...(method !== "GET" ? { "x-csrftoken": window.csrftoken } : {})
      };
      const fullInit = {
        ...commonInit,
        ...init,
        signal: abortControllerRef.current.signal,
        headers: fullHeaders
      };

      try {
        const response = await fetch(input, fullInit);
        if (!response.ok) {
          try {
            const answer = await response.json();
            // We have a valid JSON response that contains error information
            // sent back by the API
            setError(answer);
            if (isWritingRequest && !overrideBackendErrorManagement) {
              // Add a console.error to have a sentry error message
              // meaning that the back returned an "OK" error
              console.error(
                "Received an unexpected non-ok (but valid JSON) response from the server, this should be handled by the frontend but isn't."
              );
              context.current.openNotification(serverErrorNotificationOptions);
            }
            return answer;
          } catch (e) {
            // response is not a valid JSON
            setError(response.statusText);
            if (isWritingRequest) {
              context.current.openNotification(serverErrorNotificationOptions);
            }
          }
        } else if (method === "DELETE") {
          setValue(null);
        } else {
          setError(null); // Reset previous error
          try {
            const answer = await response.json();
            if (
              abortControllerRef.current &&
              abortControllerRef.current.signal.aborted
            ) {
              // This Fetch was cancelled during its process, do not use its value;
              setValue(null);
              return null;
            } else {
              setValue(answer);
              return answer;
            }
          } catch (e) {
            setError((e as any).toString());
            if (isWritingRequest) {
              context.current.openNotification(serverErrorNotificationOptions);
            }
            return null;
          }
        }
      } catch (e) {
        if (e instanceof DOMException && e.code === DOMException.ABORT_ERR) {
          // This Fetch was cancelled during its process, this is not an error
          return;
        }
        setError((e as any).toString());
        if (isWritingRequest) {
          context.current.openNotification(networkErrorNotificationOptions);
        }
      } finally {
        abortControllerRef.current = null;
        setLoading(false);
      }
    },
    [commonInit, overrideBackendErrorManagement]
  );

  const state = useMemo(
    () => ({
      value,
      error,
      loading,
      abort: doAbort
    }),
    [doAbort, error, loading, value]
  );

  return [state, doFetch];
};

export function useResourceList<ResourceType = any>(
  endpoint: string,
  queryParams?: QueryParams
): APIRequestBag<ResourceType[], [] | [QueryParams]> {
  const baseRequestBag = useCallJsonAPI<ResourceType[]>();

  const prepareQueryParams = usePrepareQueryParams(endpoint, queryParams);

  return usePrepareRequest(baseRequestBag, prepareQueryParams);
}

export function useSafeResourceList<ResourceType = any>(
  endpoint: string,
  queryParams?: QueryParams
): SafeResourceListRequestBag<ResourceType, [] | [QueryParams]> {
  const baseRequestBag = useResourceList<ResourceType>(endpoint, queryParams);

  return useDeserializer(baseRequestBag, nullIsEmptyArray);
}

export function usePaginatedResourceList<ResourceType = any>(
  endpoint: string,
  queryParams?: QueryParams
): PaginatedResourceListRequestBag<ResourceType, [] | [QueryParams]> {
  const baseRequestBag =
    useCallJsonAPI<PaginatedResourceListResponse<ResourceType>>();

  const prepareQueryParams = usePrepareQueryParams(endpoint, queryParams);

  return usePrepareRequest(baseRequestBag, prepareQueryParams);
}

export const useResourceListAndSkipPagination = <ResourceType = any>(
  endpoint: string,
  queryParams?: QueryParams
): SafeResourceListRequestBag<ResourceType, [] | [QueryParams]> => {
  const baseRequestBag = usePaginatedResourceList<ResourceType>(
    endpoint,
    queryParams
  );

  return useDeserializer(baseRequestBag, skipPagination);
};

export const useResourceItem = <ResourceType = any>(
  endpoint: string,
  id: any
): ResourceItemRequestBag<ResourceType> => {
  const [state, innerDoFetch] = useCallJsonAPI<ResourceType>();

  let baseURL = `${endpoint}/${id}`;
  if (endpoint.startsWith("/api/private")) {
    // avoids a 301 - DRF expects trailing slashes
    baseURL += "/";
  }

  const doFetch = useCallback(async () => {
    return await innerDoFetch(baseURL);
  }, [innerDoFetch, baseURL]);

  const doPatch = useCallback(
    (value: FormData) => {
      return innerDoFetch(baseURL, { method: "PATCH", body: value });
    },
    [innerDoFetch, baseURL]
  );

  const doDelete = useCallback(async () => {
    return await innerDoFetch(baseURL, { method: "DELETE" });
  }, [innerDoFetch, baseURL]);

  const callbacks = useMemo(
    () => ({
      doFetch,
      doPatch,
      doDelete
    }),
    [doDelete, doFetch, doPatch]
  );

  return [state, callbacks];
};

export const useResourceAction = <ResourceType = any>(
  endpoint: string,
  action: string
): ResourceActionRequestBag<ResourceType> => {
  const [state, innerDoFetch] = useCallJsonAPI<ResourceType>({
    commonInit: { method: "POST" }
  });

  const doAction = useCallback(
    async (itemId: any, value: any) => {
      const baseURL = `${endpoint}/${itemId}/${action}/`;
      return await innerDoFetch(baseURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(value)
      });
    },
    [endpoint, action, innerDoFetch]
  );
  return [state, doAction];
};

export const useResourceListAction = <ResourceType = any>(
  endpoint: string,
  action: string
): ResourceListActionRequestBag<ResourceType> => {
  const [state, innerDoFetch] = useCallJsonAPI<ResourceType>();
  const doAction = useCallback(
    async (value: any) => {
      const baseURL = `${endpoint}/${action}/`;
      return await innerDoFetch(baseURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(value)
      });
    },
    [endpoint, action, innerDoFetch]
  );
  return [state, doAction];
};

export const useResourceCreation = <ResourceType = any>(
  baseURL: string,
  overrideBackendErrorManagement = false
): ResourceCreationRequestBag<ResourceType> => {
  const [state, innerDoFetch] = useCallJsonAPI<ResourceType>({
    overrideBackendErrorManagement
  });

  const doCreate = useCallback(
    async (value: FormData) => {
      return await innerDoFetch(baseURL, {
        method: "POST",
        body: value
      });
    },
    [baseURL, innerDoFetch]
  );

  return [state, doCreate];
};

export const callAPIAndDownload = async (
  method: string,
  url: string,
  body?: any,
  expectedMimeType?: string,
  outFileName?: string
) => {
  return new Promise<void>((resolve, reject) => {
    // hack taken from https://www.alexhadik.com/writing/xhr-file-download/ to trigger POST request and download result
    const xhr = new XMLHttpRequest();
    xhr.open(method, url);
    xhr.responseType = "blob";
    xhr.setRequestHeader("x-csrftoken", window.csrftoken);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(body ? JSON.stringify(body) : undefined);

    xhr.onload = function () {
      if (this.status === 200) {
        // Create a new Blob object using the response data of the onload object
        const blob = new Blob([this.response], { type: expectedMimeType });
        //Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
        const a = document.createElement("a");
        a.classList.add("hidden");
        document.body.appendChild(a);
        //Create a DOMString representing the blob and point the link element towards it
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = outFileName || "data";
        a.click();
        window.URL.revokeObjectURL(url);
        return resolve();
      } else {
        return reject();
        //deal with your error state here
      }
    };
  });
};
